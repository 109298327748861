.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 1180px;
    margin: 59px auto 0;

    &_logo {
        cursor: pointer;
    }
}

.modail-nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100px;
    width: 215px;
    overflow: hidden;
    opacity: 1;
    transition: 1s;
    background-image: url(../img/bg-module.svg);

    &_button {
        position: absolute;
        top: 19px;
        left: 24px;
        background: transparent;
        border: 0;
        font-family: Montserrat400;
        font-size: 18px;
        text-align: start;
        cursor: pointer;

        &:hover {
            color: #FFDD00;
        }
    }

    &_button:last-child {
        top: 57px;
    }

    &__dis {
        height: 0;
    }
}

.nav {
    display: flex;
    width: 55%;
    justify-content: space-between;

    &_button-modul {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}

.nav-button {
    font-family: Montserrat600;
    font-size: 21px;
    background: transparent;
    border: 0px solid transparent;
    color: #000;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #FFDD00;
    }

    &_logo {
        position: relative;
        top: 2.5px;
    }

    &_act {
        color: #FFDD00;
    }
}

.nav_button-modul {
    display: flex;
    height: 30px;
}

.home-content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    max-width: 1180px;
    height: 635.05px;
    margin: 80px auto 0;
        z-index: -1;

    &_stor {
        display: flex;
        flex-direction: column;
        align-items: flex-end
    }
}

.bg-content {
    position: absolute;
    
    left: 1%;
    top: 13%;
    z-index: -1;
    // background-image: url(../img/bg-home.png);
    &>img{
        width: 800px;
    }
}

.stor-case {
    display: flex;
    flex-direction: column;
    width: 58px + 32px + 58px;

    &_button {
        display: flex;
        justify-content: space-between;

        &>button {
            padding: 0;
            background-color: transparent;
            border: 0px transparent;
            cursor: pointer;

        }

        &>button>img {
            width: 58px;
            height: 58px;
            padding: 0;
        }
    }

    &_title {
        font-family: Montserrat800;
        font-size: 16px;
        line-height: 19.5px;
        text-transform: uppercase;
    }
}

.header-mini {
    display: none;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 30px 24px;
    z-index: 100;

    &_logo {
        width: 134px;
        height: 31.5px;
    }

    &_nav-wrapper {
        display: flex;
        align-items: center;
    }

    &_nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: flex-end;
        justify-content: space-between;
        width: 306px;
        top: 14px;
        right: 8px;
        padding: 107px 80px;
        z-index: 1;
        overflow: hidden;
        transition: all 1s;
        border-radius: 20px;
        filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.04));
        background: linear-gradient(90.3deg, #fffcfc87 6.36%, #ffffffdb 101.69%);
        backdrop-filter: blur(10px);

        &>.modail-backgound {
            border-radius: 20px;
        }

        &__act {
            top: -785px;
        }


        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 20px;
            padding: 1px;
            background: linear-gradient(90deg, #fffcfc87, #ffffff);
            -webkit-mask:
                linear-gradient(#ffffff 0 0) content-box,
                linear-gradient(#ffffff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            z-index: -1;
        }
    }

}

.open-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 20px;
    height: 20px;
    transition: all 2s;
    z-index: 3;

    &_top {
        border: 1px solid #0057B8;
        transform: rotateZ(0) translate(0) scale(1);
        transition: all 1s;

        &__act {
            top: 10px;
            transform: rotateZ(45deg) translate(6px, 7px) scale(1.2, 1);
        }

    }

    &_mid {
        border: 1px solid #0057B8;
        transition: all 1s;

        &__act {
            border-color: transparent;
        }
    }

    &_botton {
        border: 1px solid #0057B8;
        transition: all 1s;

        &__act {
            top: 10px;
            transform: rotateZ(-45deg) translate(5.5px, -7px) scale(1.2, 1);
        }
    }
}

.button-mini-nav {
    position: relative;
    width: 100%;
    font-family: Montserrat600;
    font-size: 21px;
    line-height: 25.6px;
    margin-bottom: 32px;
    padding: 0;
    background: transparent;
    border: 0px solid transparent;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    text-align: center;
    z-index: 5;


    &:hover {
        color: #228AFF;
    }

    &:last-child {
        margin-bottom: 0;
    }

}

.leng-case {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    top: -2px;
    &_nav {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100px;
        height: 25px;
        overflow: hidden;
        transition: 0.2s;

        &:hover {
            height: 100px;
        }

        &>button {
            position: relative;
            top: -2px;
            left: 0px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 976px) {
    .header-mini {
        &_nav {
            align-items: center;
        }
    }

    .leng-case {
        margin: 0 20px;
    }
}

@media screen and (max-width: 860px) {
    .bg-content {
        top: 33%;
       &>img{
        width: 350px;
       } 
    }
}

@media screen and (max-width: 670px) {
    .bg-content {
        width: 350px;
        top: 33%;
        left: 0;
       &>img{
            width: 350px;
       } 
    }
}

@media screen and (max-width: 460px) {
    .bg-content {
        display: none;
       &>img{
            width: 350px;
       } 
    }
}

.type-of-cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    margin: 0 auto;
    margin-top: 20px;
    width: 164px;
    height: 19px;
}



.header-master_logo {
    width: 45.16px;
    height: 27.91px;
}

.header-visa_logo {
    width: 70.64px;
    height: 22.81px;
}

.header-google_logo {
    width: 105.53px;
    height: 22.29px;
}

.header-apple_logo {
    width: 48.36px;
    height: 19.85px;
}