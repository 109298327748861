.bg-footer{
    background-color:#0057B8;
    padding: 60px 0;
}

.footer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1180px;
    min-height: 323px;
    margin: 0 auto;
    &_case{
        display: flex;
        justify-content: space-between;
    }

    &>span{
        font-family: Montserrat800;
        font-size: 28px;
        line-height: 34px;
        color: #ffffff;
        text-transform: uppercase;
    }

    &_case{
        display: flex;
        align-items: center;
    }

    &_dop-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.footer-title{
    display: block;
    width: 50%;
    font-family: Montserrat800;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    text-transform: uppercase;
}

.footer-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width:740px) {
    .footer-title{
        font-size: 20px;
        line-height: 26px;
    }
}

.footer-marck{
    bottom: 0;
    font-family: Montserrat500;
    height: 26px;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
        margin-bottom: 40px;
    &>img{
        position: relative;
        top: -8px;
        left: 4px;
    }    
}

.nav-footer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 651px;
    

    &>a{
        height: 26px;
        font-family: Montserrat600;
        height: 26px;
        font-size: 21px;
        line-height: 25px;
        color: #ffffff;
        border: transparent;
        background: transparent;
        cursor: pointer;
        text-decoration: none;
        &:hover{
            color:#FFDD00;
        }
    }
    
}


.copyright{
    margin-top: 20px;
        width: 60%;
}

.contacts-link{
        display: flex;
            flex-direction: row;
            gap: 8px;
  
}
.contacts-link_icon {
    
    height: 24px;
}

