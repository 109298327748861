@media(max-width:1300px) {
    .title {
        font-size: 42px;
        line-height: 52.51px;
    }

    // .header{
    //     display:none
    // }
    .home-content {
        margin-top: 100px;
    }

    .home-content_title {
        margin-right: 4%;
    }

    .of-charity-hero {
        &_img {
            width: 236px;
            top: 10px
        }
    }

    .nnua_img {
        height: auto !important;
    }

    .case-och {

        &>img {
            width: 50px;
        }
    }

    .title-och {
        font-size: 26px
    }

    .info-och {
        font-size: 17px;
    }

    .how-its-worck {
        margin-top: 100px !important;
    }

    .help-f-of-ua {
        margin-top: 100px !important;

        &>span {
            font-size: 42px;
            line-height: 52.5px;
        }
    }

    .midle-backgraund {
        &>div {
            top: 100px;
        }
    }
}

@media (max-width:1220px) {
    .header {
        margin: 59px 20px 0;
    }

    .home-content {
        margin: 100px 20px 0;
    }

    .of-charity-hero {
        margin: 157px 20px 0 !important;
    }

    .how-its-worck {
        margin: 90px 20px 0 !important;
    }

    .nnua {
        margin: 160px 20px 0 !important;
    }

    .help-f-of-ua {
        margin: 160px 20px 0 !important;
    }

    .look-parner {
        margin: 59px 20px 0 !important;
    }

    .about-as {
        margin: 48px 20px 0 !important;
    }

    .contact-as {
        margin: 100px 20px 0 !important;
    }

    .modail-contact {
        max-width: 990px;
        margin: 80px auto;
        width: 74%;


        &_info {
            display: block;
        }
    }

    .footer {
        margin: 0 20px !important;
    }
}



@media (max-width:1110px) {
    .donation-modail {
        padding: 0 40px 0;
    }

    .modail-download {
        flex-direction: column;

        &_button-download {
            width: 100%;
        }

        &>span {
            max-width: 100%;
            text-align: center;
            margin: 0 0 20px;
        }
    }

    .modail-need-a-of-f {

        &_img {
            left: -16px;

            &>img {
                width: 470px;
            }
        }
    }
}

@media (max-width:1000px) {
    .of-charity-hero {
        &>img {
            width: 170px;
        }
    }
}

@media (max-width:990px) {
    .footer_case {
        align-items: baseline !important;
    }

    .nav-footer {
        flex-direction: column;
    }

    .how-its-worck {
        margin: 60px 20px 0 !important;
    }

    .nav-footer {
        width: auto !important;
        position: relative;
        top: -35px;
    }
}

@media(max-width:975px) {
    .header {
        display: none
    }

    .header-mini {
        display: flex;
        align-items: flex-start;
    }

    .header-mini_logo {
        width: 266px;
        height: auto;
    }

    .header-mini_logo:last-child {
        width: 146px;
        height: auto;
    }

}



@media (max-width: 945px) {
    .donation-modail {
        width: auto;

        &>span {
            margin-right: 10px;
        }

        &_store {
            width: 310px;
        }
    }

    .of-charity-hero {
        &>img {
            width: 170px;
        }

        &_info {
            max-width: 570px;
        }
    }

    .modail-need-a-of-f {
        height: 645px !important;

        &_img {
            left: 22px;
            bottom: -6px;

            &>img {
                width: 340px !important;
            }
        }
    }
}

@media (max-width:880px) {
    .look-parner_modail {
        padding: 40px 40px;
    }

    .case-conten-lp {

        &_text {
            max-width: 600px;

            &>span:first-child {
                font-size: 30px;
                line-height: 37px;
            }

            &>span:last-child {
                font-size: 20px;
                line-height: 24px;
            }
        }

        &_img {
            width: 200px;
        }
    }

    .modail-contact_info {
        flex-direction: column;

        &>input {
            max-width: 700px !important;
            margin: 32px 0 0 !important;
        }

        &>input:first-child {
            margin: 0 !important;
        }
    }

    .modail-need-a-of-f {
        justify-content: flex-start !important;
    }

    .list {
        &>ul {
            width: auto !important;

            &>li {

                max-width: 500px;
            }
        }
    }
}



@media (max-width:950px) {
    .list {
        &>ul {
            width: auto !important;

            &>li {

                max-width: 500px;
            }
        }
    }

    .about-as_creater {
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
    }

    .create {
        margin-top: 25px;

        &:first-child {
            margin-top: 0;
        }
    }

}

@media (max-width:846px) {


    .of-charity-hero {
        &>img {
            display: none;
        }
    }

    .donation-modail {
        flex-direction: column;
        width: 460px;
    }

    .case-och_info-case {
        max-width: 385px;
    }
}

@media (max-width:770px) {
    .modail-download {
        padding: 40px;
    }


    .list {
        &>ul {
            width: auto !important;

            &>li {

                max-width: 500px;
            }
        }
    }

    .how-its-worck_title {
        margin-bottom: 24px;
    }

    .our-volunteers {
        display: none;
    }

    .our-documents {
        margin: 48px 0;
    }

    .our-documents_title {
        font-size: 22px;
        line-height: 24px;
        margin: 0 auto 48px;
    }

    .documents_description_wrapper {
        margin: 0 0 0 20px;
    }

    .documents_title {
        width: 260px;
        height: 24px;
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 18px;
    }

    .documents_sub-title {
        width: 260px;
        height: 40px;
        font-size: 16px;
        line-height: 20px;
    }


    .documents_img {
        width: 100%;
        height: 80.38px;
    }

    .documents_pdf {
        padding: 10px;
    }


}

@media (max-width:740px) {
    .of-charity-hero {
        &_img {
            display: none;
        }
    }
}

@media (max-width:700px) {
    .title-big {
        font-size: 28px;
        line-height: 32px;
    }

    .our-support-wrapper {
        margin: 60px auto;
    }

    .logo-wrapper{
        display: flex;
        flex-direction: column; 
    }

    .header-mini_logo {
        width: 120px;
        
    }
    .header-mini_logo:last-child {
        width: auto;
        height: 90px;
    }

    .home-content {
        height: 540.05px;
    }

    .home-content_title {
        width: 245px;
    }

    .count {

        font-size: 48px;

    }

    .about-as_modail-info {
        margin-top: 24px;
        margin-bottom: 63px;
    }


    .header-master_logo {
        width: 22.18px;
        height: 14px;
    }

    .header-visa_logo {
        width: 34.7px;
        height: 11.25px;
    }

    .header-google_logo {
        width: 71.49px;
        height: 14px;
    }

    .header-apple_logo {
        width: 23.75px;
        height: 19px;
    }
         .copyright {
             width: 100%;
         }

}

@media (max-width:620px) {
    .of-charity-hero {
        display: flex;
        flex-direction: column;
    }


    .stor-case {
        margin-top: 40px;
    }

    .button-donation__header {
        margin: 48px auto 0;
        width: 262px;
        height: 44px;
    }

    .case-conten-lp {
        flex-direction: column !important;
        align-items: center !important;

        &_img {
            width: auto;
            max-width: 390px;
        }
    }

    .donation-modail {
        width: 360px;
        margin: 60px auto 0;
    }



    .home-content {
        margin: 6.45px 25px !important;
    }


    .donation-modail {
        width: 250px;
        height: 57px;
        justify-content: center;
        margin: 100px auto 0;
        padding: 16px 14px;
        border-radius: 16px;

        &>span {
            position: absolute;
            font-size: 22px;
            line-height: 26.8px;
            left: 0;
            top: -56px;
            z-index: -1;
        }

        &_store {
            width: 100%;

            &>.arrow {
                display: block;
                width: 30px;
            }

            &>img {
                width: 155px;
            }

            &>img:last-child {
                width: 63px;
            }
        }
    }

    .title {
        font-size: 22px;
        line-height: 24px;
    }

    .sub-title {
        font-size: 16px;
    }

    .of-charity-hero {
        margin: 48px 20px 0 !important;

        &_title {
            margin-bottom: 24px !important;
        }
    }

    .case-och {
        flex-direction: column;

        &_img {
            width: 66px !important;
            margin-bottom: 24px;
        }
    }

    .title-och {
        font-size: 20px !important;
    }

    .info-och {
        font-size: 16px !important;

    }

    .stor-case {
        width: 102px;

        &_button {
            &>a>img {
                width: 40px;
                height: 40px;
            }
        }

        &_title {
            font-size: 11px;
            line-height: 13.5px;
        }
    }

    .how-its-worck {
        margin: 58px 25px !important;
    }

    .hwch-info {
        flex-direction: column-reverse;
        margin-bottom: 16px;

        &_counter {
            width: auto !important;
            border-bottom: transparent !important;
        }
    }

    .nnua {
        margin: 48px 25px 0 !important;
    }

    .help-f-of-ua {
        margin: 51px 25px 0 !important;

        &_title {
            &>span:last-child {
                margin-top: 0 !important;
                font-size: 16px !important;
            }
        }
    }

    .modail-need-a-of-f {
        height: 566px !important;
        padding: 68px 25px 0;
        margin: 27px 0 0;

        &_img {
            &>img {
                margin-top: 100px;
                width: 211px !important;
            }
        }

        &_title {
            &>span {
                font-size: 20px;
                line-height: 22px !important;
            }
        }

        &::before {
            top: -2px;
            left: -2px;
            padding: 2px;
        }
    }

    .list {
        &>ul {
            margin: 0;
            padding-left: 10px;

            &>li {
                font-size: 16px;
                line-height: 19.5px;
                padding: 32px 0 17px 0;

                &::marker {
                    content: url(../img/markMini.svg)
                }
            }
        }
    }

    .help-f-of-ua>span {
        font-size: 22px;
        line-height: 26.8px;
        color: #ffffff;
    }

    .modail-backgound {
        border-radius: 20px;
    }

    .modail-download {
        max-height: 148px;
        margin: 80px 0 0;
        padding: 24px 29px;
        border-radius: 20px;

        &>span {
            font-size: 16px;
            line-height: 19.5px;
            text-align: start;
        }

        &_button-download {
            flex-direction: row;
            align-items: center;

            &>a:first-child {
                margin-right: 10px;
            }

            &>a>img {
                // width: 121px;
            }
        }

        &::before {
            top: -2px;
            left: -2px;
            padding: 2px;
            border-radius: 24px;
        }
    }

    .look-parner {

        &_title {
            &>span {}
        }

        &_modail {
            margin: 47.8px 0 0;
            padding: 24px 21px;
        }
    }

    .corusel-partner {
        margin-top: 87px;
    }

    .case-conten-lp {
        position: relative;


        &_text {
            &>span:first-child {
                font-size: 20px;
                line-height: 24.4px;
            }

            &>span:last-child {
                font-size: 16px;
                line-height: 19.5px;
            }

        }

        &_img {
            position: relative;
            width: 211.3px;
            margin: 16px 0 0 auto;
        }
    }

    .about-as {
        &_sub-title {
            font-size: 20px;
            line-height: 24.4px;
            margin: 24px 0 0;
        }

        &_list {
            &>.list>ul>li {
                padding: 24px 0 24px 28px;
            }
        }

        &_modail-info {
            border-radius: 20px;
            padding: 19px 35px;

            &>span {
                font-size: 14px;
                line-height: 17px;
            }

        }
    }

    .boolu {
        display: none;
    }

    .boolb {
        display: none;
    }

    .modail-contact {
        width: -webkit-fill-available;
        // min-width: 255px;
        // padding:40px 36px;
        // border-radius: 20px;

        &::before {
            top: -2px;
            left: -2px;
            padding: 2px;
            border-radius: 24px;

        }

        &_info {

            .name-input,
            .email-input {
                padding: 13px 28px 13px 28px !important;
                border-radius: 20px !important;

                &::placeholder {
                    font-size: 16px;
                    line-height: 19.5px;
                }
            }
        }

        &_subject {

            padding: 13px 28px 13px 28px !important;
            border-radius: 20px !important;

            &::placeholder {
                font-size: 16px;
                line-height: 19.5px;
            }
        }

        &_message {

            padding: 13px 28px 13px 28px !important;
            border-radius: 20px !important;

            &::placeholder {
                font-size: 16px;
                line-height: 19.5px;
            }
        }

        &_button {
            &>button {}
        }
    }

    .footer {
        flex-direction: column;
        position: relative;

        &_case {
            position: relative;

            &>img {
                width: 116px;
            }

            &>span {
                font-size: 22px;
                line-height: 26px;
                margin: 0 0 24px;
            }

            &>.footer-marck {
                font-size: 13px;
                line-height: 15.85px;
                margin: 24px 0 0;
            }
        }
    }

    .nav-footer {
        top: -10px;
        margin: 0 0 0 auto;

        &>button {
            font-family: Montserrat400;
            font-size: 16px;
            line-height: 19.5px;
        }
    }

    .stor-case {
        margin: 25px 0 24px;
    }

    .footer-marck {}

    .donation-modail {
        &_stor {
            width: 100%;
        }
    }

    .top-backgraund {
        filter: blur(0);

        &>div {
            top: 36px;
            right: 45px;

            &>img {
                position: relative;
                    height: 100%;
                    top: 7px;
                    right: 0;
            }
        }
    }

    .midle-backgraund {
        &>div {
            top: 226px;
            left: 166px;

            &>img {
                height: 70%;
            }
        }
    }

    .bottun-backgraund {
        &>div {
            top: 303px;
            left: -185px;

            &>img {
                width: 190%;
            }
        }
    }
}

@media (max-width:560px) {
    

  
}

@media (max-width:460px) {
    .home-content{
        align-items: center;
        &_title{
            margin: 0;
        }
    }
    .cost{
        position: relative;
        right: auto;
        top: 6.4%;
    }
    
    .home-content{
        margin: 0.45px 25px !important;
    }
}
